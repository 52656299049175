<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        @back="hRedirect('users-list')"
        @save="createItem"
        :title="this.currentItem.firstname + ' ' + this.currentItem.name"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-form @submit.stop.prevent="handleSubmit(createItem)" @reset.prevent="resetForm">

              <b-row>
                <b-col cols="6">
                  <b-form-group :label="$t('Member Role')">
                    <validation-provider #default="{ errors }" :name="$t('Member Role')" rules="required">
                      <b-form-select v-model="currentItem.role" :options="roleOptions"
                                     :placeholder="$t('Please select an Event Type')"
                      >
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="Object.keys(validation).includes('role')" class="text-danger">{{
                          $t(validation.role[0])
                        }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">

                  <b-form-group :label="$t('Firstname')">
                    <validation-provider #default="{ errors }" :name="$t('First Name')" rules="required">
                      <b-form-input v-model="currentItem.firstname" :placeholder="$t('Firstname')"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="Object.keys(validation).includes('firstname')" class="text-danger">{{
                          $t(validation.firstname[0])
                        }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group :label="$t('Name')">
                    <validation-provider #default="{errors}" :name="$t('Name')" rules="required">
                      <b-form-input v-model="currentItem.name" :placeholder="$t('Name')"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="Object.keys(validation).includes('name')" class="text-danger">{{
                          $t(validation.name[0])
                        }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">

                  <b-form-group :label="$t('Position')">
                    <b-form-input v-model="currentItem.position" :placeholder="$t('Position')"/>
                  </b-form-group>

                </b-col>
                <b-col cols="6">
                  <b-form-group :label="$t('Department')">
                    <b-form-input v-model="currentItem.department" :placeholder="$t('Department')"/>
                  </b-form-group>

                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <b-form-group :label="$t('Email')">
                    <validation-provider #default="{errors}" :name="$t('Email')" rules="required">
                      <b-form-input v-model="currentItem.email" :placeholder="$t('Email')"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="Object.keys(validation).includes('email')" class="text-danger">{{
                          $t(validation.email[0])
                        }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <b-form-checkbox value="1" v-model="currentItem.notification"
                                   class="custom-control-success mt-1 mb-1"
                  >
                    {{ $t('Inform user about registration?') }}
                  </b-form-checkbox>
                </b-col>
              </b-row>


              <div class="d-flex mt-1">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit"
                          @click.prevent="createItem"
                >
                  {{ $t('Add User') }}
                </b-button>
              </div>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
  </component>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BFormTimepicker,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  BSidebar,
  BToast,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions'
import {alphaNum, email, required} from '@validations'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BFormRadioGroup,
    BFormTimepicker,
    BFormTextarea,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {

      currentItem: {
        role: 'user',
        firstname: '',
        name: '',
        position: '',
        department: '',
        email: '',
        notification: 0,
      },
      loaded: true,

      roleOptions: [
        {
          value: 'admin',
          text: this.$t('admin'),
        },
        {
          value: 'user',
          text: this.$t('user'),

        }
      ],
      validation: {},
      validate: false,
    }
  },

  methods: {
    createItem() {
      let url = `/client/users`
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            if (success) {
              this.$http.post(url, this.currentItem)
                  .then(response => {
                    this.$router.push(
                        {
                          name: 'users-list',
                        },
                        () => setTimeout(() => {
                          this.$bvToast.toast(this.$i18n.t('User successfully added'), {
                            title: this.$i18n.t('Success'),
                            variant: 'success',
                            toaster: 'b-toaster-bottom-right'
                          })
                        })
                    )
                  })
                  .catch(error => {
                    if (error.response.status == 422) {
                      this.validation = error.response.data.errors
                      this.validate = true
                    } else {
                      this.$bvToast.toast(this.$i18n.t(error.response.data.message), {
                        title: this.$i18n.t(error.response.data.message),
                        variant: 'danger',
                        toaster: 'b-toaster-bottom-right',
                      })
                    }
                  })
            }
          })
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },

  },
  mounted() {
  },
}
</script>

<style>
</style>
