var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.loaded === false ? 'div' : 'div',{tag:"component"},[_c('b-card-actions',{ref:"refreshCard",attrs:{"action-back":"","action-save":"","title":this.currentItem.firstname + ' ' + this.currentItem.name,"no-body":"","disable-loading-on-refresh":"","loaded":_vm.loaded},on:{"back":function($event){return _vm.hRedirect('users-list')},"save":_vm.createItem}}),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.createItem)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Member Role')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Member Role'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.roleOptions,"placeholder":_vm.$t('Please select an Event Type')},model:{value:(_vm.currentItem.role),callback:function ($$v) {_vm.$set(_vm.currentItem, "role", $$v)},expression:"currentItem.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(Object.keys(_vm.validation).includes('role'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.role[0])))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Firstname')}},[_c('validation-provider',{attrs:{"name":_vm.$t('First Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('Firstname')},model:{value:(_vm.currentItem.firstname),callback:function ($$v) {_vm.$set(_vm.currentItem, "firstname", $$v)},expression:"currentItem.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(Object.keys(_vm.validation).includes('firstname'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.firstname[0])))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('Name')},model:{value:(_vm.currentItem.name),callback:function ($$v) {_vm.$set(_vm.currentItem, "name", $$v)},expression:"currentItem.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(Object.keys(_vm.validation).includes('name'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.name[0])))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Position')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Position')},model:{value:(_vm.currentItem.position),callback:function ($$v) {_vm.$set(_vm.currentItem, "position", $$v)},expression:"currentItem.position"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Department')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Department')},model:{value:(_vm.currentItem.department),callback:function ($$v) {_vm.$set(_vm.currentItem, "department", $$v)},expression:"currentItem.department"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Email')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('Email')},model:{value:(_vm.currentItem.email),callback:function ($$v) {_vm.$set(_vm.currentItem, "email", $$v)},expression:"currentItem.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(Object.keys(_vm.validation).includes('email'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.email[0])))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-checkbox',{staticClass:"custom-control-success mt-1 mb-1",attrs:{"value":"1"},model:{value:(_vm.currentItem.notification),callback:function ($$v) {_vm.$set(_vm.currentItem, "notification", $$v)},expression:"currentItem.notification"}},[_vm._v(" "+_vm._s(_vm.$t('Inform user about registration?'))+" ")])],1)],1),_c('div',{staticClass:"d-flex mt-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"success","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.createItem.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('Add User'))+" ")])],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }